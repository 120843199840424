<template>
  <div class="maxBox">
    <div class="content">
      <h1 class="animated bounceInDown">我们的优势，为什么选择我们？</h1>
      <h6></h6>
      <div class="card animated bounceInDown">
        <div class="tips">
          <h3>消费趋势：</h3>
          <ul>
            <li>
              移动互联网方便了人们的生活，人们的衣食住行消费更高效率，更便利化，更碎片化。
            </li>
            <li>
              随着人工，房租成本的不断升高，好多传统行业例如餐饮、超市便利店等更小型化，贴近居民社区，开始推进无人化、自助化以提高营业时间，减少运营成本。
            </li>
            <li>
              运动产业随着国家大力提倡和国民的健康意识逐步提高，消费需求未来会持续增长。
            </li>
          </ul>
        </div>
        <div class="contrast">
          <div class="left animated slideInLeft">
            <h3>无人自助台球项目优势</h3>
            <ul>
              <li>
                场地兼容性大，地下室，商场，下沉广场，写字楼和街边商铺都可，面积从60-300平米（使用面积）都可以。
              </li>
              <li>
                更容易实现自助化，一次性投资，不需要学习专业技能，营业收入前期持续增长后期收入稳定，受大环境影响较小。
              </li>
            </ul>
          </div>
          <div class="right animated slideInRight">
            <h3>加入友动体育的优势与前景</h3>
            <ul>
              <li>
                不收品牌服务费，不捆绑器材，装修等。前三年运营管理费按照流水的8%收取，第四年开始按照4%收取。
              </li>
              <li>
                友动设计开发了行业领先的APP，有专业技术团队，持续优化升级软件。
              </li>
              <li>
                友动和行业上游大牌器材供应商建立长期战略合作关系，其中有星牌台球，L.KD台球，价格会低于市场价，到货期更短。
              </li>
              <li>友动可协助合作者选址，提供数据分析。</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.maxBox {
  background: url("../assets/img/bg3.jpg") 100% no-repeat;
  background-size: cover;
  height: calc(100vh - 120px);
  color: #fff;
  padding-top: 180px;
  font-family: "Microsoft YaHei UI";
}
.content {
  h6 {
    width: 100px;
    height: 4px;
    margin: 30px auto;
    background: #0286fe;
  }
  h1 {
    text-align: center;
    margin: auto;
  }
  .card {
    width: 85%;
    margin: auto;
    color: rgb(65, 63, 63);
    background: rgba(255, 255, 255, 0.7);
    padding: 20px 30px;
    border-radius: 10px;
  }
  .tips {
    text-align: left;
    line-height: 1.5em;
    li {
      margin-left: 3.5em;
      // text-indent: 2em;
      line-height: 1.5em;
      list-style: square;
    }
  }
  .contrast {
    h3 {
      padding: 20px 0 10px;
    }
    li {
      margin-left: 3.5em;
      // text-indent: 2em;
      line-height: 1.5em;
      list-style: square;
    }
  }
}
</style>
